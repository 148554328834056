import { IWebcamItem } from 'api/region/types';
import classNames from 'classnames';
import { WebcamInfo } from 'components/common/WebcamItem';
import React, { useCallback } from 'react';
import { formatAgoIfIn24TimestampOrString } from 'util/formatAgo';
import { IResortPathInfo } from 'api/resort/types';
import { BLUR_CONTENT_STYLE_NAME } from 'theme';
import styles from './styles.module.scss';

export interface IProps {
  isFresh: boolean;
  cameras: Array<IWebcamItem & { resort?: IResortPathInfo }>;
  onCamSelect?: (cam: IWebcamItem) => void;
  currentId?: string;
  showSummitDepth?: boolean;
  showSnowReport?: boolean;
  imageLink?: (r: IResortPathInfo) => string,
  titleLink?: (r: IResortPathInfo) => string,
  skipSingleCamera: boolean,
}

export const CameraList = ({
  cameras,
  isFresh,
  onCamSelect,
  currentId,
  showSnowReport,
  showSummitDepth,
  imageLink,
  titleLink,
  skipSingleCamera,
}: IProps) => {
  const onCamSelectCb = useCallback((cam?: IWebcamItem) => (cam ? onCamSelect?.(cam) : undefined), [onCamSelect]);

  const shouldSkipRender = skipSingleCamera ? (cameras.length <= 1) : (cameras.length < 1);

  if (shouldSkipRender) {
    return null;
  }

  return (
    <div className={classNames(styles.box, isFresh ? '' : BLUR_CONTENT_STYLE_NAME)}>
      {cameras.map((cam) => (
        <WebcamInfo
          blur={!isFresh}
          imageLink={imageLink}
          titleLink={titleLink}
          showSnowReport={showSnowReport}
          showSummitDepth={showSummitDepth}
          onClick={onCamSelectCb}
          cam={cam}
          subtitle={formatAgoIfIn24TimestampOrString(cam.date)}
          title={cam.title}
          key={cam.uuid}
          isCurrent={cam.uuid === currentId}
        />
      ))}
    </div>
  );
};
