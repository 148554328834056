import {
  DistanceUnitFormatKey,
  TemperatureUnitFormatKey, useDistanceFormatter, useTemperatureFormatter,
} from 'i18n/unitFormat';

export const DistanceUnit = ({
  type, values, formatAll, removeEqual, noValue, separator,
}:{
  values: Array<number | string | undefined> | number | string | undefined,
  type: DistanceUnitFormatKey,
  noValue?: I18nKeyGenerated['common'],
  separator?: string,
  formatAll?: boolean,
  removeEqual?: boolean,
}) => {
  const formatFn = useDistanceFormatter(type);
  const val = formatFn(values, separator, noValue, formatAll, removeEqual);
  return <>{val}</>;
};

export const TemperatureUnit = ({
  type, values, formatAll, removeEqual, noValue, separator,
}:{
  values: Array<number | string | undefined> | number | string | undefined,
  type: TemperatureUnitFormatKey,
  noValue?: I18nKeyGenerated['common'],
  separator?: string,
  formatAll?: boolean,
  removeEqual?: boolean,
}) => {
  const formatFn = useTemperatureFormatter(type);
  const val = formatFn(values, separator, noValue, formatAll, removeEqual);
  return <>{val}</>;
};

export const SnowUnit = (props:{
  values: Array<number | string | undefined> | number | string | undefined,
  noValue?: I18nKeyGenerated['common'],
  separator?: string,
  format?: 'all' | 'last',
}) => <DistanceUnit type="cmOrInchShort" {...props} />;
