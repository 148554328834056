import { IRegionShort } from 'api/region/types';
import {
  IContactInformation,
  IOpeningTimeItem,
  IResortPageInfo,
  IResortPathInfo,
  IResortReviews,
  IResortStrengthsOverview,
  IReviewsGeneral,
} from 'api/resort/types';
import { socialLinks } from 'components/blocks/Footer';
import { weekDays } from 'components/resort/LiftTimes';
import { getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import React from 'react';
import UrlLogoSnow from 'public/assets/white-logo.svg';
import UrlLogoSki from 'public/assets/white-logo_ski.svg';

import { useRouter } from 'next/router';
import { Routes } from 'routes';
import { IMarkupReview } from 'types';
import { formatDateFromStr } from 'util/formatDate';

export type Props = {
  resort: IResortPageInfo,
  averageReviews?: IReviewsGeneral | null,
  reviewList?: IResortReviews | null,
  contact?: IContactInformation | null,
  awards?: IResortStrengthsOverview | null
  schedule?: Array<IOpeningTimeItem>;
};

export const htmlProps = (data: Object) => ({ dangerouslySetInnerHTML: { __html: JSON.stringify(data) } });

export const normalizeLiftTime = (s: string | null | undefined, fallback: string) => {
  if (!s) {
    return fallback;
  }
  const parts = s.split(':');
  if (parts.length === 3) {
    const [h, m] = parts;
    return `${h}:${m}`;
  }
  return s;
};

export const buildMarkupForResort = (
  {
    resort,
    contact,
    averageReviews,
    reviewList,
    awards,
    schedule: rawSchedule,
  }: Props,
  t: (key: I18nKeyGenerated['common'], params?: any) => string,
) => {
  const route = useRouter();
  const pageURL = process.env.NEXT_PUBLIC_WEBSITE_DOMAIN + route.asPath;
  const reviews: Array<IMarkupReview> = [];
  reviewList?.reviewsList.forEach((review) => {
    const reviewDate = formatDateFromStr(review.date, 'yyyy-MM-dd');
    const name = ((review.firstName || review.firstName) ? `${review.firstName} ${review.lastName}` : '').trim();
    reviews.push({
      '@type': 'Review',
      datePublished: reviewDate,
      reviewBody: review?.review,
      author: {
        '@type': 'Person',
        name: name || 'Anonymous',
      },
    });
  });
  const data: Record<string, any> = {
    '@context': 'https://schema.org',
    '@type': 'SkiResort',
    name: resort.title,
    address: {
      '@type': 'PostalAddress',
      addressCountry: resort.region.country,
      addressRegion: resort.region.state,
      addressLocality: resort.region.title,
    },
    url: pageURL,
    image: resort.image,
  };

  if (contact && data.address) {
    const { address } = data;
    address.streetAddress = contact.address.postOffice;
  }
  if (contact?.email) {
    data.email = `mailto:${contact?.email}`;
  }
  if (contact?.phoneNumber) {
    data.telephone = `${contact?.phoneNumber}`;
  }

  if (reviews) {
    data.review = reviews;
  }
  if (resort?.logo) {
    data.logo = resort?.logo;
  }
  if (resort?.priceRange) {
    data.priceRange = resort?.priceRange;
  }

  if (averageReviews) {
    let total = 0;
    for (let i = 1; i <= 5; i += 1) {
      total += (averageReviews?.totalReviews[i as 1 | 2 | 3 | 4 | 5] ?? 0);
    }

    data.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: averageReviews?.generalRating.overall,
    };

    if (total) {
      data.aggregateRating.ratingCount = total;
      data.aggregateRating.reviewCount = total;
    } else {
      delete data.aggregateRating;
    }
    if (data.aggregateRating && !data.aggregateRating.ratingValue) {
      delete data.aggregateRating;
    }
  }

  if (awards) {
    const categories: { [key: string]: string } = {
      overall: t('resort.overview.overallRegion'),
      allMtnTerrain: t('resort.overview.allMtnTerrainIRegion'),
      familyFriendly: t('resort.overview.familyFriendlyRegion'),
      terrainPark: t('resort.overview.terrainParkRegion'),
      beginner: t('resort.overview.beginnerRegion'),
      apresSki: t('resort.overview.apresSkiRegion'),
      smallSkiArea: t('resort.overview.smallSkiAreaRegion'),
      intermediate: t('resort.overview.intermediateRegion'),
      expert: t('resort.overview.expertRegion'),
    };
    data.award = awards.ratings.map((val) => `${t('resort.overview.positionPrefix', { value: val.position })} ${categories[val.category]} ${val.region.title}`);
  }

  if (rawSchedule) {
    const schedule: Array<IOpeningTimeItem> = (rawSchedule ?? []).filter((a) => !!a.from && !!a.to);
    const hasSchedule = !!schedule.length;
    const days = schedule.filter((d) => !d.isNight).sort((a: IOpeningTimeItem) => a.day);
    const nights = schedule.filter((d) => !!d.isNight).sort((a: IOpeningTimeItem) => a.day);
    const labels = ['Mo', 'Tu', 'Wd', 'Th', 'Fr', 'Sa', 'Su'];
    const displayDays = weekDays.filter((d) => !!schedule.find((tt) => tt.day === d));

    if (hasSchedule) {
      data.openingHours = displayDays.map((d) => {
        const day = days.find((tt) => tt.day === d);
        const night = nights.find((tt) => tt.day === d);
        const parts = [labels[d]];
        if (day) parts.push(`${normalizeLiftTime(day.from, t('common.na'))}-${normalizeLiftTime(day.to, t('common.na'))}`);
        if (night) parts.push(`${normalizeLiftTime(night.from, t('common.na'))}-${normalizeLiftTime(night.to, t('common.na'))}`);
        if (day || night) {
          return parts.join(' ');
        }
        return null;
      }).filter((d) => !!d);
    }
  }

  if (contact) {
    data.geo = {
      '@type': 'GeoCoordinates',
      latitude: contact.address.lat,
      longitude: contact.address.lng,
    };
  }
  return htmlProps(data);
};

export const LdJsonSkiResort = (props: Props) => {
  const { t } = useOTSTranslation('common');
  const data = buildMarkupForResort(props, t);

  return <script type="application/ld+json" {...data} />;
};

export const LdJsonBreadCrumbsResort = ({
  resort,
  region,
  fn,
}: { resort: IResortPathInfo, region: IRegionShort, fn: (r: IResortPathInfo) => string }) => {
  const data = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement:
      [
        {
          '@type': 'ListItem',
          position: 1,
          item:
            {
              '@id': Routes.regionSnowReport(region),
              name: region.title,
            },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item:
            {
              '@id': fn(resort),
              name: resort.title,
            },
        },
      ],
  };
  const props = htmlProps(data);

  return <script type="application/ld+json" {...props} />;
};

export const LdJsonSearchAction = () => {
  const data = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: `${process.env.NEXT_PUBLIC_WEBSITE_DOMAIN}`,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${process.env.NEXT_PUBLIC_WEBSITE_DOMAIN}?q={search_term_string}`,
      },
      'query-input': 'required name=search_term_string',
    },
  };

  const props = htmlProps(data);

  return <script type="application/ld+json" {...props} />;
};

export const LdJsonOrganization = () => {
  const { t } = useOTSTranslation('common');
  const showUSLogo = [ELocale.EN_US, ELocale.EN_GB, ELocale.CS_CZ, ELocale.SK_SK].includes(getLocale() as ELocale);

  const data = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: process.env.NEXT_PUBLIC_WEBSITE_DOMAIN,
    name: t('homePage.companyName'),
    description: t('homePage.description'),
    foundingDate: 1968,
    logo: showUSLogo ? UrlLogoSnow : UrlLogoSki,
    legalName: t('homePage.legalName'),
    numberOfEmployees: t('homePage.employees'),
    sameAs: [socialLinks.twitter, socialLinks.facebook, socialLinks.instagram],
  };

  const props = htmlProps(data);

  return <script type="application/ld+json" {...props} />;
};
