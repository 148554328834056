import { useDistanceUnit, useTemperatureUnit } from 'components/UnitSwitch/context';
import {
  roundCmInch,
  roundMFeet,
  roundKmMile,
  roundTemperature, roundKmphMph, roundAcHectars,
} from 'i18n/units';
import { I18nUnitKey } from 'i18n/unitTranslations';
import { DistanceType, TempType } from 'i18n/types';
import { useOTSTranslation } from 'i18n/useOTSTranslation';

export type DistanceUnitConverter = {
  normalize: (value: number | string, t?: DistanceType) => number | undefined;
  i18nKey: {
    metric: I18nUnitKey;
    imperial: I18nUnitKey;
  };
};

export type TempUnitConverter = {
  normalize: (value: number | string, t?: TempType) => number | undefined;
  i18nKey: {
    celsius: I18nUnitKey;
    fahrenheit: I18nUnitKey;
  };
};

export const DistanceUnitFormatMap = {
  cmOrInch: {
    normalize: roundCmInch,
    i18nKey: {
      [DistanceType.METRIC]: 'cm',
      [DistanceType.IMPERIAL]: 'inch',
    },
  } as DistanceUnitConverter,
  cmOrInchShort: {
    normalize: roundCmInch,
    i18nKey: {
      [DistanceType.METRIC]: 'cmShort',
      [DistanceType.IMPERIAL]: 'inchShort',
    },
  } as DistanceUnitConverter,
  mOrFeet: {
    normalize: roundMFeet,
    i18nKey: {
      [DistanceType.METRIC]: 'm',
      [DistanceType.IMPERIAL]: 'feet',
    },
  } as DistanceUnitConverter,
  mOrFeetShort: {
    normalize: roundMFeet,
    i18nKey: {
      [DistanceType.METRIC]: 'mShort',
      [DistanceType.IMPERIAL]: 'feetShort',
    },
  } as DistanceUnitConverter,
  kmOrMile: {
    normalize: roundKmMile,
    i18nKey: {
      [DistanceType.METRIC]: 'km',
      [DistanceType.IMPERIAL]: 'mile',
    },
  } as DistanceUnitConverter,
  acOrHa: {
    normalize: roundAcHectars,
    i18nKey: {
      [DistanceType.METRIC]: 'hectares',
      [DistanceType.IMPERIAL]: 'acres',
    },
  } as DistanceUnitConverter,
  kmhOrMileh: {
    normalize: roundKmphMph,
    i18nKey: {
      [DistanceType.METRIC]: 'kmph',
      [DistanceType.IMPERIAL]: 'mph',
    },
  } as DistanceUnitConverter,
};

export type DistanceUnitFormatKey = keyof typeof DistanceUnitFormatMap;

export const TemperatureUnitFormatMap = {
  temperature: {
    normalize: roundTemperature,
    i18nKey: {
      [TempType.CELSIUS]: 'celsius',
      [TempType.FAHRENHEIT]: 'fahrenheit',
    },
  } as TempUnitConverter,
};

export type TemperatureUnitFormatKey = keyof typeof TemperatureUnitFormatMap;

export type DistanceFormatterFn = (
  value: string | number | undefined | Array<string | number | undefined>,
  separator?: string,
  fallback?: I18nKeyGenerated['common'],
  formatAll?: boolean,
  removeEqual?: boolean,
  noValuePlaceholder?: string,
) => string;

export const useDistanceFormatter = (type: keyof typeof DistanceUnitFormatMap) => {
  const { t } = useOTSTranslation('unit');
  const { t: commonT } = useOTSTranslation('common');
  const dt = useDistanceUnit();
  return (
    value: string | number | undefined | Array<string | number | undefined>,
    separator?: string,
    fallback?: I18nKeyGenerated['common'],
    formatAll?: boolean,
    removeEqual?: boolean,
    noValuePlaceholder?: string,
  ) => {
    const values: Array<string | number> = (Array.isArray(value) ? value : [value])
      .filter((val) => (typeof val !== 'undefined' && val !== null) || !!noValuePlaceholder)
      .filter((val, index, arr) => (removeEqual ? (index === 0 || arr[index - 1] !== val) : true)) as Array<string | number>;
    if (!values.length && fallback) {
      return commonT(fallback);
    }
    const formatted = values.map((val, index) => {
      if (typeof val !== 'undefined' && val !== null) {
        const count = DistanceUnitFormatMap[type].normalize(val, dt);
        if (index === values.length - 1 || formatAll) {
          return t(DistanceUnitFormatMap[type].i18nKey[dt] as any, {
            count,
          });
        }
        return count;
      }
      return noValuePlaceholder;
    });
    return formatted.join(separator || '');
  };
};

export const useTemperatureFormatter = (type: keyof typeof TemperatureUnitFormatMap = 'temperature') => {
  const { t } = useOTSTranslation('unit');
  const { t: commonT } = useOTSTranslation('common');
  const dt = useTemperatureUnit();
  return (
    value: string | number | undefined | Array<string | number | undefined>,
    separator?: string,
    fallback?: I18nKeyGenerated['common'],
    formatAll?: boolean,
    removeEqual?: boolean,
  ) => {
    const values: Array<string | number> = (Array.isArray(value) ? value : [value])
      .filter((val) => typeof val !== 'undefined')
      .filter((val, index, arr) => (removeEqual ? (index === 0 || arr[index - 1] !== val) : true)) as Array<string | number>;
    if (!values.length && fallback) {
      return commonT(fallback);
    }
    const formatted = values.map((val, index) => {
      const count = TemperatureUnitFormatMap[type].normalize(val, dt);
      if (index === values.length - 1 || formatAll) {
        return t(TemperatureUnitFormatMap[type].i18nKey[dt] as any, {
          count,
        });
      }
      return count;
    });
    return formatted.join(separator || '');
  };
};

export const useSnowFormat = () => useDistanceFormatter('cmOrInchShort');
