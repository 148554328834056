import { DistanceType, TempType } from 'i18n/types';

export function isNumber(value: any): value is number {
  return typeof value === 'number' && Number.isFinite(value);
}

export const toNumber = (value: any) => {
  if (!isNumber(value)) {
    try {
      const number = Number.parseFloat(value);
      if (Number.isFinite(number) && !Number.isNaN(number)) {
        return number;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return undefined;
  }
  return value;
};

export const cmToInch = (value: number) => value * 0.393700787;
export const inchToCm = (value: number) => value / 0.393700787;

export const mToFeet = (value: number) => value * 3.2808399;
export const feetTom = (value: number) => value / 3.2808399;

export const kmToMile = (value: number) => value * 0.621371192;
export const mileToKm = (value: number) => value / 0.621371192;

export const acToHectars = (value: number) => value * 0.404686;

export const kmhToMileh = (value: number) => value * 0.621371192;

// export const kmhToMileh = (value: number) => value * 0.621371192;

export const mpsToKmh = (value: number) => value * 3.6;

export const celsiusToFahrenheit = (value: number) => value * (9 / 5) + 32;

/** TODO: add missing converters on demand @see https://github.com/MountainNews/oxs-i18n/blob/master/lib/oxs-metrics.js */

/**
 * Methods below accept metrics/celsius by default and convert them if param tells so
 */

export const normalizeCmInch = (
  value: number,
  t: DistanceType = DistanceType.METRIC,
) => (t === DistanceType.METRIC ? value : cmToInch(value));

export const normalizeMFeet = (
  value: number,
  t: DistanceType = DistanceType.METRIC,
) => (t === DistanceType.METRIC ? value : mToFeet(value));

export const normalizeKmMile = (
  value: number,
  t: DistanceType = DistanceType.METRIC,
) => (t === DistanceType.METRIC ? value : kmToMile(value));

export const normalizeAcHectars = (
  value: number,
  t: DistanceType = DistanceType.METRIC,
) => (t === DistanceType.IMPERIAL ? value : acToHectars(value));

export const normalizeKmphMph = (
  value: number,
  t: DistanceType = DistanceType.METRIC,
) => (t === DistanceType.METRIC ? value : kmhToMileh(value));

export const roundDistance = (
  value: number | string,
  fn: (value: number, t: DistanceType) => number,
  t: DistanceType = DistanceType.METRIC,
  showDecimals: boolean = false,
  roundToGreater: boolean = false,
) => {
  const n = toNumber(value);
  if (typeof n !== 'undefined') {
    if (showDecimals) {
      return Number(fn(n, t).toFixed(1));
    }
    const val = fn(n, t);
    return roundToGreater ? Math.ceil(val) : Math.round(val);
  }
  return undefined;
};

export const roundTemperature = (
  value: number | string,
  t: TempType = TempType.CELSIUS,
) => {
  const n = toNumber(value);
  if (typeof n !== 'undefined') {
    if (t === TempType.FAHRENHEIT) {
      return Math.round(celsiusToFahrenheit(n));
    }
    return Math.round(n);
  }
  return undefined;
};

export const roundCmInch = (
  value: number | string,
  t: DistanceType = DistanceType.METRIC,
) => roundDistance(value, normalizeCmInch, t, value < 2.5, false);

export const roundMFeet = (
  value: number | string,
  t: DistanceType = DistanceType.METRIC,
) => roundDistance(value, normalizeMFeet, t);

export const roundKmMile = (
  value: number | string,
  t: DistanceType = DistanceType.METRIC,
) => roundDistance(value, normalizeKmMile, t, true);

export const roundAcHectars = (
  value: number | string,
  t: DistanceType = DistanceType.METRIC,
) => roundDistance(value, normalizeAcHectars, t);

export const roundKmphMph = (
  value: number | string,
  t: DistanceType = DistanceType.METRIC,
) => roundDistance(value, normalizeKmphMph, t);
