import classNames from 'classnames';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { BlockHeader } from 'components/blocks/BlockHeader';
import { BoxWithShadow } from 'components/blocks/BoxWithShadow';
import { ReactComponent as SvgTime } from 'public/assets/icons/time.svg';
import { IOpeningTimeItem, IOpenStat } from 'api/resort/types';
import { DateTime } from 'luxon';
import { getCountry, getLocale } from 'i18n/locale';
import { useMemo } from 'react';
import { formatDateFromStr, SHORT_DATE_FORMAT } from 'util/formatDate';
import { BLUR_CONTENT_STYLE_NAME } from 'theme';
import styles from './styles.module.scss';

const generateLabels = () => {
  let day = DateTime.now().startOf('day').set({
    weekday: 0,
  });
  const labels = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 7; i++) {
    day = day.set({
      weekday: i,
    });
    labels.push(day.toFormat('cccc'));
  }
  return labels;
};

export const weekDays = [0, 1, 2, 3, 4, 5, 6];

export const normalizeLiftTime = (s: string | null | undefined, fallback: string) => {
  if (!s) {
    return fallback;
  }
  const parts = s.split(':');
  if (parts.length === 3) {
    const [h, m] = parts;
    if (getCountry() === 'US') {
      if (+h >= 13) {
        return `${+h - 12}:${m} PM`;
      }
      if (+h < 1) {
        return `${12}:${m} AM`;
      }
      return `${h}:${m} AM`;
    }
    return `${h}:${m}`;
  }
  return s;
};

export const LiftTimes = ({
  isFresh, status, schedule: rawSchedule,
}: {
  isFresh: boolean, schedule?: Array<IOpeningTimeItem>, status: IOpenStat,
}) => {
  const { t } = useOTSTranslation('common');
  const schedule: Array<IOpeningTimeItem> = useMemo(() => (rawSchedule ?? []).filter((a) => !!a.from && !!a.to), [rawSchedule]);
  const hasSchedule = !!schedule.length;
  const days = schedule.filter((d) => !d.isNight).sort((a: IOpeningTimeItem) => a.day);
  const nights = schedule.filter((d) => !!d.isNight).sort((a: IOpeningTimeItem) => a.day);
  const labels = useMemo(() => generateLabels(), [getLocale()]);
  const shouldRenderNights = !!nights.length;
  const displayDays = weekDays.filter((d) => !!schedule.find((tt) => tt.day === d));
  if (!status.openingDate && !status.closingDate && !hasSchedule) {
    return null;
  }
  return (
    <BoxWithShadow>
      <BlockHeader useH3 title={t('resort.skiReport.liftHoursOperation')} />
      <div className={styles.opening}>
        <div className={styles.item}>
          <span className={classNames('h3', styles.h3, isFresh ? undefined : BLUR_CONTENT_STYLE_NAME)}>
            {status.openingDate ? formatDateFromStr(status.openingDate, SHORT_DATE_FORMAT) : ''}
          </span>
          <div>{t('resort.skiReport.projectedOpening')}</div>
        </div>
        <div className={styles.item}>
          <span className={classNames('h3', styles.h3, isFresh ? undefined : BLUR_CONTENT_STYLE_NAME)}>
            {status.closingDate ? formatDateFromStr(status.closingDate, SHORT_DATE_FORMAT) : ''}
          </span>
          <div>{t('resort.skiReport.projectedClosing')}</div>
        </div>
      </div>
      {hasSchedule && (
        <div className={classNames(styles.schedule, isFresh ? undefined : BLUR_CONTENT_STYLE_NAME)}>
          <div className={styles.col}>
            <span className={classNames('h4', styles.h4)}>{t('resort.skiReport.liftHoursOperation')}</span>
            {
              displayDays.map((d) => {
                const day = days.find((tt) => tt.day === d);
                return (
                  <div key={d}>
                    <div className={styles.day}>{labels[d]}</div>
                    {day && (
                      <div>
                        <SvgTime />
                        <span>{`${normalizeLiftTime(day.from, t('common.na'))} - ${normalizeLiftTime(day.to, t('common.na'))}`}</span>
                      </div>
                    )}
                    {!day && (
                      <div>
                        -
                      </div>
                    )}
                  </div>
                );
              })
            }
          </div>
          {shouldRenderNights && (
            <div className={styles.col}>
              <span className={classNames('h4', styles.h4)}>{t('resort.skiReport.nightSkiing')}</span>
              {displayDays.map((d) => {
                const day = nights.find((tt) => tt.day === d);
                return (
                  <div key={d}>
                    <div className={styles.day}>{labels[d]}</div>
                    {day && (
                      <div>
                        <SvgTime />
                        <span>{`${normalizeLiftTime(day.from, t('common.na'))} - ${normalizeLiftTime(day.to, t('common.na'))}`}</span>
                      </div>
                    )}
                    {!day && (
                      <div>
                        -
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </BoxWithShadow>
  );
};
